<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          size="sm"
          href="#rankingGeral"
          @click="addCarteira('S1')"
        >
          S1
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          size="sm"
          href="#rankingGeral"
          @click="addCarteira('S2')"
        >
          S2
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          size="sm"
          href="#rankingGeral"
          @click="addCarteira('S3')"
        >
          S3
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-5" v-if="carteira != null">
      <b-col v-if="ranking_orcamento.length > 0">
        <h5 class="text-center">Orçamento - Negócio % Meta</h5>
        <b-card no-body>
          <div class="ranking-style overflow-auto">
            <b-table-simple striped responsive>
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Agencia</b-th>
                  <b-th>Orçamento</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in ranking_orcamento">
                  <b-td class="text-center">
                    {{ tr.codigo }} - {{ tr.agencia }}
                  </b-td>
                  <b-td class="text-center">
                    {{ tr.orcamento | media }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="ranking_media.length > 0">
        <h5 class="text-center">Resultado - Superação</h5>
        <b-card title="Meta" no-body>
          <div class="ranking-style overflow-auto">
            <b-table-simple striped responsive>
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Agencia</b-th>
                  <b-th>Meta</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in ranking_media">
                  <b-td class="text-center">
                    {{ tr.codigo }} - {{ tr.agencia }}
                  </b-td>
                  <b-td class="text-center">
                    {{ tr.meta | media }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="ranking_resultado.length > 0">
        <h5 class="text-center">Resultado - Valor Nominal</h5>
        <b-card title="Resultado" no-body>
          <div class="ranking-style overflow-auto">
            <b-table-simple striped responsive>
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Agencia</b-th>
                  <b-th>Resultado</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in ranking_resultado">
                  <b-td class="text-center">
                    {{ tr.codigo }} - {{ tr.agencia }}
                  </b-td>
                  <b-td class="text-center">
                    {{ tr.resultado | dinheiro }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="ranking_iap.length > 0">
        <h5 class="text-center">IAP Médio</h5>
        <b-card title="IAP Médio" no-body>
          <div class="ranking-style overflow-auto">
            <b-table-simple striped responsive>
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Agencia</b-th>
                  <b-th>Média</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in ranking_iap">
                  <b-td class="text-center">
                    {{ tr.codigo }} - {{ tr.agencia }}
                  </b-td>
                  <b-td class="text-center">
                    {{ tr.media | media }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="!ranking_orcamento.length && !ranking_media.length && !ranking_resultado.length && !ranking_iap.length && carteira != null">
        <b-card class="text-center">
          <h2>
            Encontramos nenhum dado correspondente em nosso sistema!
          </h2>
          <p>
            <small class="text-muted">Entre em contato com os administradores caso isso for um erro!</small>
          </p>
          <p class="mt-2">
            <b-img
              fluid
              width="300"
              :src="require('@/assets/images/pages/empty.svg')"
            />
          </p>
        </b-card>
      </b-col>
    </b-row>
    <div v-else class="text-center mt-4">
      <b-card>
        <h2>Selecione um porte para mostrar o ranking!</h2>
        <p class="mt-2">
          <b-img
            fluid
            width="300"
            :src="require('@/assets/images/pages/investimentos.svg')"
          />
        </p>
      </b-card>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,
      key: 1,
      produto_id: null,
      produtoNome: null,

      ranking_orcamento: [],
      ranking_media: [],
      ranking_resultado: [],
      ranking_iap: [],

      carteira: null,

      filterShow: false,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    addCarteira(value) {
      this.carteira = value;
    },

    fetchRanking() {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchRanking", {
          pathAPI: "agencia",
          porte: this.carteira,
        })
        .then((response) => {
          let valor = [];
          let quantidade = [];
          let media = [];
          let iap = [];
          this.ranking_orcamento = [];
          this.ranking_resultado = [];
          this.ranking_media = [];
          this.ranking_iap = [];

          if (response.ranking_orcamento.length > 0) {
            response.ranking_orcamento.forEach((value, index) => {
              valor.push(value);
            });

            valor.sort(function (a, b) {
              if (a.valor < b.valor) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_orcamento = valor;
          }

          if (response.ranking_resultado.length > 0) {
            response.ranking_resultado.forEach((value, index) => {
              quantidade.push(value);
            });

            quantidade.sort(function (a, b) {
              if (a.quantidade < b.quantidade) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_resultado = quantidade;
          }

          if (response.ranking_media.length > 0) {
            response.ranking_media.forEach((value, index) => {
              media.push(value);
            });

            media.sort(function (a, b) {
              if (a.media < b.media) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_media = media;
          }

          if (response.ranking_iap.length > 0) {
            response.ranking_iap.forEach((value, index) => {
              iap.push(value);
            });

            iap.sort(function (a, b) {
              if (a.iap < b.iap) {
                return +1;
              } else {
                return false;
              }
            });

            this.ranking_iap = iap;
          }

          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    carteira() {
      this.fetchRanking();
    },
  },
};
</script>
<style lang="scss">
.ranking-style {
  width: 100%;
  height: 600px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>
 